import React from "react";

import { HistoryLink } from "../../components";
import { eventData } from "./constants";

export const History = () => (
    <div>
        <h1>Historie</h1>
        <br />
        <h2>Tábory</h2>
        {eventData.filter((event) => event.isCamp).map((camp) => (
            <HistoryLink key={camp.link} {...camp} />
        ))}
        <h2>Další</h2>
        {eventData.filter((event) => !event.isCamp).map((camp) => (
            <HistoryLink key={camp.link} {...camp} />
        ))}
    </div>
);
