import React from "react";

import { bem } from "../../utils";
import "./InterpretBadge.scss";

const classNames = bem("interpret-badge");

export const InterpretBadge = ({ title, imageSource }) => (
    <div className={classNames()}>
        <img
            className={classNames("image")}
            src={imageSource}
            alt={title}
            title={title}
        />
    </div>
);
