import React from "react";

import { IntroVideo } from "../../components";
import "./Home.scss";

export const Home = () => (
    <div className="home">
        <h1>Úvod</h1>
        <p>Letní stanové tábory probíhají pod záštitou TJ Sokol Tasov již několik desítek let.</p>
        <p>Hlavními cíli našeho kolektivu vedoucích je vytvořit přátelské vztahy mezi dětmi a procvičit jejich tělo i mysl a u toho se také dobře bavit.</p>
        <p>Naše tábořiště se nachází v blízkosti obce Tasov a je obklopeno krásnou a rozmanitou přírodou, čehož při aktivitách často využíváme.</p>
        <p>Každý tábor se nese v duchu nějakého tématu, do kterého se snažíme zařadit nějaké morální ponaučení.</p>
        <p>Náš turnus je určen pro děti od 8 do 15 let. Děti jsou ubytovány v podsadových stanech.</p>
        <p><IntroVideo /></p>
    </div>
);
