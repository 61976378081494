import React, { useState } from "react";

export const PreviewImage = ({ alt, image, previewImage, className }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return imageLoaded ? (
        <img
            src={image}
            alt={alt}
            className={className}
        />
    ) : (
        <img
            src={previewImage}
            alt={alt}
            className={className}
            onLoad={() => setImageLoaded(true)}
        />
    );
};
