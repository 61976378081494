import React, { useRef, useState } from "react";

export const IntroVideo = () => {
    const videoRef = useRef();
    const [isVideoVisible, setIsVideoVisible] = useState(false);

    return (
        <>
            <video
                ref={videoRef}
                style={{ width: "100%", display: isVideoVisible ? "inline-block" : "none" }}
                controls={false}
                autoPlay={false}
                muted={true}
                loop={true}
                onContextMenu={(event) => event.preventDefault()}
                playsInline={true}
            >
                <source src="https://data.pavelpo.cz/tabor/video.mp4" type="video/mp4" />
            </video>
            <img
                src="/graphics/water.jpg"
                style={{ width: "100%", cursor: "pointer", display: isVideoVisible ? "none" : "inline-block" }}
                alt="Táborové video"
                onContextMenu={(event) => event.preventDefault()}
                onClick={() => {
                    videoRef.current.play();
                    setTimeout(() => {
                        setIsVideoVisible(true);
                    }, 200);
                }}
            />
        </>
    );
};
