import React from "react";

import { bem, getMilliseconds } from "../../utils";
import { SupervisorBadge, Countdown, Counter } from "../../components";
import { supervisorsData, START_TIMESTAMP } from "./constants";
import "./Year.scss";

const classNames = bem("year");

export const Year = () => (
    <div className={classNames()}>
        <h1>Letos</h1>
        <div className={classNames("panel")}>
            <h2>Základní informace</h2>
            <div className={classNames("row")}>
                <div className={classNames("left-part")}>
                    Téma<br />
                    Datum konání
                </div>
                <div>
                    BANG!<br />
                    21.–28. 7. 2024
                </div>
            </div>
        </div>
        <div className={classNames("panel")} style={{ textAlign: "justify" }}>
            V srdci nekonečných plání amerického kontinentu se rozprostírá divoký západ. Místo plné tajemství, dobrodružství a nezdolného ducha.
            Místo protkané příběhy odvahy, romantiky, ale bohužel i bojů a násilí. Denně se řeší hazard, sázky, přepadávání vlaků, dostavníků
            a bank. Každé město tak potřebuje svého šerifa, který se musí prokázat svou rozvahou a schopností uklidňovat konflikty. Šerif to však
            nebude mít jednoduché… Západ je totiž opravdu divoký.
        </div>
        {START_TIMESTAMP > getMilliseconds() ? (
            <div className={classNames("panel")}>
                <div className={classNames("row")}>
                    <span className={classNames("countdown-description")}>Začínáme za</span>
                    <Countdown countdownTimestamp={START_TIMESTAMP} />
                </div>
            </div>
        ) : null}
        <div className={classNames("panel")}>
            <h2>Stav přihlášek</h2>
            <div className={classNames("row")}>
                <Counter actual={70} maximum={70} />
                <span className={classNames("counter-description")}>přihlášek je již obsazeno</span>
            </div>
        </div>
        <div className={classNames("panel")}>
            <h2>Vedoucí</h2>
            {supervisorsData.map((supervisor) => (
                <SupervisorBadge
                    key={supervisor.id}
                    {...supervisor}
                />
            ))}
        </div>
    </div>
);
