/* eslint-disable max-len */

import * as assets from "./assets";

import { shuffleArray } from "../../utils";

const data = [
    { id: "1", name: "Honzík", image: assets.image1, hoverImage: assets.hover1, previewImage: assets.imagePreview1, previewHoverImage: assets.imagePreview1 },
    { id: "2", name: "Šuty", image: assets.image2, hoverImage: assets.hover2, previewImage: assets.imagePreview2, previewHoverImage: assets.imagePreview2 },
    { id: "3", name: "Míra", image: assets.image3, hoverImage: assets.hover3, previewImage: assets.imagePreview3, previewHoverImage: assets.imagePreview3 },
    { id: "5", name: "Hop", image: assets.image5, hoverImage: assets.hover5, previewImage: assets.imagePreview5, previewHoverImage: assets.hoverPreview5 },
    { id: "6", name: "Terka", image: assets.image6, hoverImage: assets.hover6, previewImage: assets.imagePreview6, previewHoverImage: assets.hoverPreview6 },
    { id: "7", name: "Janča", image: assets.image7, hoverImage: assets.hover7, previewImage: assets.imagePreview7, previewHoverImage: assets.imagePreview7 },
    { id: "9", name: "Marsí", image: assets.image9, hoverImage: assets.hover9, previewImage: assets.imagePreview9, previewHoverImage: assets.hoverPreview9 },
    { id: "10", name: "Jirka", image: assets.image10, hoverImage: assets.hover10, previewImage: assets.imagePreview10, previewHoverImage: assets.hoverPreview10 },
    { id: "12", name: "Adam", image: assets.image12, hoverImage: assets.hover12, previewImage: assets.imagePreview12, previewHoverImage: assets.hoverPreview12 },
    { id: "15", name: "David", image: assets.image15, hoverImage: assets.hover15, previewImage: assets.imagePreview15, previewHoverImage: assets.hoverPreview15 },
    { id: "16", name: "Míša", image: assets.image16, hoverImage: assets.hover16, previewImage: assets.imagePreview16, previewHoverImage: assets.hoverPreview16 },
    { id: "18", name: "Pája", image: assets.image18, hoverImage: assets.hover18, previewImage: assets.imagePreview18, previewHoverImage: assets.hoverPreview18 },
    { id: "22", name: "Klárka", image: assets.image22, hoverImage: assets.hover22, previewImage: assets.imagePreview22, previewHoverImage: assets.hoverPreview22 },
    { id: "23", name: "Áďa", image: assets.image23, hoverImage: assets.hover23, previewImage: assets.imagePreview23, previewHoverImage: assets.hoverPreview23 },
    { id: "24", name: "Áďa", image: assets.image24, hoverImage: assets.hover24, previewImage: assets.imagePreview24, previewHoverImage: assets.hoverPreview24 },
    { id: "26", name: "Barča", image: assets.image26, hoverImage: assets.hover26, previewImage: assets.imagePreview26, previewHoverImage: assets.hoverPreview26 },
    { id: "27", name: "Jíťa", image: assets.image27, hoverImage: assets.hover27, previewImage: assets.imagePreview27, previewHoverImage: assets.hoverPreview27 },
    { id: "28", name: "Iveta", image: assets.image28, hoverImage: assets.hover28, previewImage: assets.imagePreview28, previewHoverImage: assets.hoverPreview28 },
    { id: "29", name: "Týna", image: assets.image29, hoverImage: assets.hover29, previewImage: assets.imagePreview29, previewHoverImage: assets.hoverPreview29 },
    { id: "30", name: "Týbí", image: assets.image30, hoverImage: assets.hover30, previewImage: assets.imagePreview30, previewHoverImage: assets.hoverPreview30 },
];

export const supervisorsData = shuffleArray(data);

export const START_TIMESTAMP = 1721561400000;
