import React from "react";
import { Link } from "react-router-dom";

import "./MenuLink.scss";

export const MenuLink = ({ link, text }) => (
    <Link to={link}>
        <div className="menu-link">
            {text.toUpperCase()}
        </div>
    </Link>
);
