import React from "react";
import PropTypes from "prop-types";

import { bem } from "../../utils";
import { PreviewImage } from "../PreviewImage";
import "./SupervisorBadge.scss";

const classNames = bem("supervisor-badge");

export const SupervisorBadge = ({ image, hoverImage, name, previewImage, previewHoverImage, disabled }) => {
    const hoverDisabled = disabled || !hoverImage || !previewHoverImage;

    return (
        <div className={classNames()}>
            <div className={classNames("image-container")}>
                <PreviewImage
                    className={classNames("image", { disabled: hoverDisabled })}
                    image={image}
                    previewImage={previewImage}
                    alt={name}
                />
                {!hoverDisabled && (
                    <PreviewImage
                        className={classNames("hover-image")}
                        image={hoverImage}
                        previewImage={previewHoverImage}
                        alt={name}
                    />
                )}
            </div>
            <div className={classNames("title")}>
                {name}
            </div>
        </div>
    );
};

SupervisorBadge.propTypes = {
    image: PropTypes.string.isRequired,
    hoverImage: PropTypes.string,
    name: PropTypes.string.isRequired,
    previewImage: PropTypes.string.isRequired,
    previewHoverImage: PropTypes.string,
};

SupervisorBadge.defaultProps = {
    hoverImage: undefined,
    previewHoverImage: undefined,
};
