import React, { useState } from "react";
import useInterval from "@use-it/interval";

import "./Counter.scss";

const SLOW_COUNTER_SPEED = 50;
const FAST_COUNTER_SPEED = 25;

export const Counter = ({ actual, maximum }) => {
    const [count, setCount] = useState(0);
    const [isRunning, setIsRunning] = useState(true);

    const getIntervalDelay = () => {
        if (isRunning) {
            if (actual < maximum / 2) {
                return SLOW_COUNTER_SPEED;
            }
            return FAST_COUNTER_SPEED;
        }
        return null;
    };

    useInterval(() => {
        if (count < actual) {
            setCount((prevCount) => prevCount + 1);
        } else {
            setIsRunning(false);
        }
    }, getIntervalDelay());

    return (
        <div className="counter">
            {count}/{maximum}
        </div>
    );
};
