/* eslint-disable max-len */

import React from "react";

import { FileLink } from "../../components";
import { bem } from "../../utils";
import "./Files.scss";

const classNames = bem("files");

export const Files = () => (
    <div className={classNames()}>
        <h1>Soubory</h1>
        <FileLink
            title="Důležité informace"
            link="/files/Důležité informace.pdf"
            text="Souhrn důležitých informací pro přečtení před odevzdáním přihlášky."
        />
        <FileLink
            title="Potvrzení o zdravotní způsobilosti"
            link="/files/Potvrzení o zdravotní způsobilosti.pdf"
            text="Vyplňuje lékař, platnost dva roky. Bez tohoto dokumentu není možné přijmout dítě na tábor."
        />
        <p className={classNames("small-text")}>
            Pro uložení souboru klikněte pravým tlačítkem myši na název dokumentu a vyberte z kontextové nabídky možnost Uložit odkaz jako...
        </p>
    </div>
);
