import React from "react";
import PropTypes from "prop-types";

export const Link = ({ url, newTab, children }) => (
    url ? (
        <a
            href={url}
            target={newTab && "_blank"}
            rel={newTab && "noopener noreferrer"}
        >
            {children}
        </a>
    ) : (
        <span>
            {children}
        </span>
    )
);

Link.propTypes = {
    url: PropTypes.string,
    newTab: PropTypes.bool,
};

Link.defaultProps = {
    url: undefined,
    newTab: false,
};
