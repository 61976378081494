import React from "react";

import { bem } from "../../utils";
import { PreviewImage } from "../PreviewImage";
import backgroundImage from "./assets/background.jpg";
import backgroundImagePreview from "./assets/background-preview.jpg";
import "./Logo.scss";

const classNames = bem("logo");

export const Logo = () => (
    <div className={classNames()}>
        <PreviewImage
            className={classNames("background")}
            image={backgroundImage}
            previewImage={backgroundImagePreview}
            alt="Obrázek pozadí loga"
        />
        <a href="/">
            <div className={classNames("title")}>
                TÁBOR SKALKA
                <div className={classNames("subtitle")}>III. turnus</div>
            </div>
        </a>
    </div>
);
