import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Menu, Logo, Footer } from "./components";
import { Home, Year, Files, History, Contacts } from "./screens";
import "./styles.scss";

export const App = () => (
    <BrowserRouter>
        <>
            <Menu />
            <Logo />
            <div className="container">
                <Routes>
                    <Route path="/" exact={true} element={<Home />} />
                    <Route path="letos" element={<Year />} />
                    <Route path="historie" element={<History />} />
                    <Route path="soubory" element={<Files />} />
                    <Route path="kontakty" element={<Contacts />} />
                </Routes>
            </div>
            <Footer />
        </>
    </BrowserRouter>
);
