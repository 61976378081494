import React from "react";

import { bem } from "../../utils";
import { PreviewImage } from "../PreviewImage";
import { Link } from "../Link";
import { clickImage } from "./assets";
import "./HistoryLink.scss";

const classNames = bem("history-link");

export const HistoryLink = ({
    image,
    link,
    title = "…",
    year,
    previewImage,
}) => {
    const titleText = `${title} (${year})`;

    return (
        <div className={classNames()} title={titleText}>
            <Link url={link} newTab={true}>
                <div className={classNames("image-container")}>
                    <PreviewImage
                        className={classNames("image")}
                        image={image}
                        previewImage={previewImage}
                        alt={titleText}
                    />
                    <img
                        className={classNames("click-image")}
                        src={clickImage}
                        alt="Tlačítko pro otevření odkazu"
                    />
                </div>
                <div className={classNames("title")}>
                    {title}
                </div>
                <div className={classNames("year")}>
                    {year}
                </div>
            </Link>
        </div>
    );
};
