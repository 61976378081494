import React, { useState } from "react";
import useInterval from "@use-it/interval";

import "./Countdown.scss";

const DAY_STRINGS = ["den", "dny", "dní"];
const HOUR_STRINGS = ["hodinu", "hodiny", "hodin"];
const MINUTE_STRINGS = ["minutu", "minuty", "minut"];
const SECOND_STRINGS = ["vteřinu", "vteřiny", "vteřin"];

const countToString = (count, countStrings) => {
    switch (count) {
    case 1: return countStrings[0];
    case 2:
    case 3:
    case 4: return countStrings[1];
    default: return countStrings[2];
    }
};

const timeDistanceToText = (distance) => {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const daysText = `${days} ${countToString(days, DAY_STRINGS)}`;
    const hoursText = `${hours} ${countToString(hours, HOUR_STRINGS)}`;
    const minutesText = `${minutes} ${countToString(minutes, MINUTE_STRINGS)}`;
    const secondsText = `${seconds} ${countToString(seconds, SECOND_STRINGS)}`;
    return `${daysText} ${hoursText} ${minutesText} ${secondsText}`;
};

export const Countdown = ({ countdownTimestamp }) => {
    const [text, setText] = useState("");
    const [isRunning, setIsRunning] = useState(true);

    useInterval(() => {
        const now = new Date().getTime();
        const distance = countdownTimestamp - now;

        if (distance < 0) {
            setIsRunning(false);
        } else {
            setText(timeDistanceToText(distance));
        }
    }, isRunning ? 100 : null);

    return (
        <div className="countdown">
            {text}
        </div>
    );
};
