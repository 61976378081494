import React, { useState } from "react";

import { bem } from "../../utils";
import { Link } from "../Link";
import "./FileLink.scss";

const classNames = bem("file-link");

const useSwitcher = (defaultValue) => {
    const [value, setValue] = useState(defaultValue);

    return [value, () => setValue(true), () => setValue(false)];
};

export const FileLink = ({ link, title, text }) => {
    const [hovered, hover, unhover] = useSwitcher(false);

    return (
        <div
            className={classNames({ active: !!link })}
            onMouseEnter={hover}
            onMouseLeave={unhover}
        >
            <Link url={link} newTab={true}>
                <div className={classNames("text-big")}>
                    {title}
                    <span className={classNames("pointer", { active: hovered })}> ◀</span>
                </div>
                <div className={classNames("text-description")}>{text}</div>
            </Link>
        </div>
    );
};
