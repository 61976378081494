import React from "react";

import { MenuLink } from "../MenuLink";
import "./Menu.scss";

export const Menu = () => (
    <div className="menu">
        <MenuLink link="/" text="Úvod" />
        <MenuLink link="letos" text="Letos" />
        <MenuLink link="historie" text="Historie" />
        <MenuLink link="soubory" text="Soubory" />
        <MenuLink link="kontakty" text="Kontakty" />
    </div>
);
